import { DropDown, InputField, RangeSliderDoublePointer, TextEditor } from "components";
import React, { memo, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { boolean, couplesLookingForOptionsCouples, couplesMartialStatusOPtions, eventMode, genderOptions, relationshipStatusOptions, singlesLookingForOptions, singlesMartialStatusOPtions } from "shared/constants";
import { eventSlugGeneration } from 'utils/validations/event';
import img from "assets/createNewEvent/images/Picture.png";
// Assets
import { createNewEvent, editEvent, getAllEventCategory, getAllEventTags, getAllHosts, getAllLocation, getEventById, getSlug } from 'apis/events';
import { addLabelAndValueInData, convertTo24HourFormat } from 'utils/dataConversion';
import { getAllPlansCategory } from "apis/plans";
import AdvancedImageCropping from "components/NEW/image/imageCropping";
import { decodeFromBase64, encodeToBase64 } from "utils/encodeAndDecode";
import { useNavigate, useParams } from "react-router-dom";
import { deleteEventBySlugFromLocal, extractId, getEventBasicDetailsFromLocalBySlug } from "../actions";
import { EVENTS } from "common/routes";


function Index() {
    const navigate = useNavigate()
    const { type, id } = useParams();
    const [slugSuggestionBtnVisibility, setSlugSuggestionBtnVisibility] = useState(true)
    const [imgUrl, setImgUrl] = useState(null)
    const [advanceCropping, setAdvanceCropping] = useState(false)
    const [eventDescription, setEventDescription] = useState('')
    const [options, setOptions] = useState({
        host: [],
        location: [],
        category: [],
        tags: [],
        plans: [],
        isFree: boolean,
        priceForCoupleOrPerson: boolean
    })

    const [eventData, setEventData] = useState({
        // Left Side
        title: null,
        start_date: null,
        end_date: null,
        relationship_status: null,
        marital_status: [],
        gender: null,
        categories: [],
        looking_for: [],
        tags: [],
        age_from: 18,
        age_to: 55,
        isFree: true,
        total_slots: null,
        gender_based_slots: false,
        description: null,

        // Right Side
        slug: null,
        start_time: null,
        end_time: null,
        host: null,
        co_hosts: [],
        location: null,
        mode: null,
        online_event_link: null,
        freemium_plans: [],
        price: 0,
        price_for_couple: false,
        max_coin_redeemable: 0,
        slots_for_men: null,
        slots_for_female: null,
        image: null,
    });

    // Image Handlers
    // Image Section
    const fileInputRef = useRef(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            const reader = new FileReader();
            reader.onloadend = () => {
                updateState("image", file);
                setImgUrl(reader.result);
                setAdvanceCropping(!advanceCropping)
            };
            reader.readAsDataURL(file);
        } else {
            updateState("image", null);
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleCropComplete = async (croppedFile) => {
        updateState('image', croppedFile);
        setImgUrl(URL.createObjectURL(croppedFile));
        setAdvanceCropping(!advanceCropping)
    }


    const updateState = (key, value) => {
        setEventData(prev => ({ ...prev, [key]: value }))
    }

    const fetchAllHosts = async () => {
        const res = await getAllHosts()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, host: transformedData }))
    }

    const fetchAllLocation = async () => {
        const res = await getAllLocation()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, location: transformedData }))
    }

    const fetchAllEventCategory = async () => {
        const res = await getAllEventCategory()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, category: transformedData }))
    }

    const fetchAllEventTags = async () => {
        const res = await getAllEventTags()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, tags: transformedData }))
    }

    const fetchAllPlanCategory = async () => {
        const res = await getAllPlansCategory()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setOptions(prev => ({ ...prev, plans: transformedData }))
    }

    const generateSlug = async () => {
        try {
            const eventInfo = {
                start_date: eventData?.start_date,
                title: eventData?.title,
                category: eventData?.categories[0],
            }
            await eventSlugGeneration.validate(eventInfo, { abortEarly: false });
            const res = await getSlug(eventInfo)
            if (res?.success) {
                updateState('slug', res?.data)
                setSlugSuggestionBtnVisibility(false)
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const fetchEventData = async () => {
        const res = await getEventById(decodeFromBase64(id))
        if (res?.success) {
            setEventData({
                ...res?.data,
                start_date: res?.data?.start_date.split('T')[0],
                end_date: res?.data?.end_date.split('T')[0],
                categories: extractId(res?.data?.categories),
                co_hosts: extractId(res?.data?.co_hosts),
                host: extractId(res?.data?.host),
                location: extractId(res?.data?.location),
                tags: extractId(res?.data?.tags),
                start_time: convertTo24HourFormat(res?.data?.start_time),
                end_time: convertTo24HourFormat(res?.data?.end_time)
            })
            setEventDescription(res?.data?.description)
            setImgUrl(res?.data?.image)
        }
    }

    const contentLeftSide = [
        { type: 'text', key: 'title', label: "Event Title", },
        { type: 'text', key: 'start_date', label: "Start Date", inputFiledType: 'date' },
        { type: 'text', key: 'end_date', label: "End Date", inputFiledType: 'date' },
        { type: 'dropDown', key: 'relationship_status', label: "Relationship Status", options: relationshipStatusOptions },
        { type: 'dropDown', key: 'marital_status', label: "Marital status", options: eventData.relationship_status === "single" ? singlesMartialStatusOPtions : (eventData.relationship_status === 'couple' ? couplesMartialStatusOPtions : [...singlesMartialStatusOPtions, ...couplesMartialStatusOPtions]), mode: "multiple", },
        { type: 'dropDown', key: "gender", label: "Gender", options: genderOptions },
        { type: 'dropDown', key: "categories", label: "Category", options: options?.category, mode: "multiple" },
        { type: 'dropDown', key: 'tags', label: "Tags", options: options?.tags, mode: "multiple" },
        { type: 'dropDown', key: "looking_for", label: "LookingFor", options: [...singlesLookingForOptions, ...couplesLookingForOptionsCouples], mode: "multiple", isVisible: true },
        { type: 'rangeSliderDoublePointer', key1: 'age_from', key2: 'age_to', label1: "Age from", label2: "Age to", range: [18, 100] },
        { type: 'dropDown', key: 'isFree', label: "Is Free", options: options?.isFree, },
        { type: 'text', key: 'total_slots', label: "Total Slots", },
        { type: 'dropDown', key: 'gender_based_slots', label: "Gender Based Slots", options: boolean },
    ]

    const contentRightSide = [
        { type: 'text', key: 'slug', label: "Slug", isVisible: true },
        { type: 'text', key: 'start_time', label: "Start Time", inputFiledType: 'time', isVisible: true },
        { type: 'text', key: 'end_time', label: "End Time", inputFiledType: 'time', isVisible: true },
        { type: 'dropDown', key: 'host', label: "Host", options: options?.host, isVisible: true },
        { type: 'dropDown', key: 'co_hosts', label: "Co Hosts", options: options?.host, mode: "multiple", isVisible: true },
        { type: 'dropDown', key: 'location', label: "Location", options: options?.location, isVisible: true },
        { type: 'dropDown', key: 'mode', label: "Mode", options: eventMode, isVisible: true },
        { type: 'text', key: 'online_event_link', label: "Meeting Url", isVisible: eventData?.mode === 'online' ? true : false },
        { type: 'dropDown', key: 'freemium_plans', label: "Plans", options: options?.plans, mode: "multiple", isVisible: true },
        { type: 'text', key: "price", label: "Price", isVisible: eventData?.isFree == false },
        { type: 'dropDown', key: "price_for_couple", label: "Price For Couple", options: options?.priceForCoupleOrPerson, isVisible: eventData?.isFree == false },
        { type: 'text', key: 'max_coin_redeemable', label: "Max Coin Redeemable", isVisible: eventData?.isFree == false },
        { type: 'text', key: 'slots_for_men', label: "Slots For Men", isVisible: eventData?.gender_based_slots },
        { type: 'text', key: 'slots_for_female', label: "Slots For Female", isVisible: eventData?.gender_based_slots },

    ]

    useEffect(() => {
        fetchAllHosts()
        fetchAllLocation()
        fetchAllEventCategory()
        fetchAllEventTags()
        fetchAllPlanCategory()
        if (id && decodeFromBase64(type) !== 'local') {
            fetchEventData()
        } else {
            const res = getEventBasicDetailsFromLocalBySlug(decodeFromBase64(id))
            setEventData({ ...res })
            setEventDescription(res?.description)
        }
        if (relationshipStatusOptions) {
            relationshipStatusOptions.push({ value: 'both', label: 'Both' })
        }
    }, [])

    const handleSaveInLocal = () => {
        if (eventData.slug) {
            setEventData((prev) => {
                const updatedData = { ...prev, description: eventDescription };
                const existingData = JSON.parse(localStorage.getItem('eventData')) || [];
                const dataIndex = existingData.findIndex(item => item[updatedData.slug]);
                if (dataIndex !== -1) {
                    existingData[dataIndex][updatedData.slug] = updatedData;
                } else {
                    existingData.push({ [updatedData.slug]: updatedData });
                }
                localStorage.setItem('eventData', JSON.stringify(existingData));
                toast.success('Saved to local storage');
                return updatedData;
            });
        } else {
            toast.error('Slug Cannot be empty')
        }
    };



    const handleSaveAndContinue = async () => {
        try {
            const decodedType = decodeFromBase64(type)
            const eventType = (decodedType === 'create' || decodedType === 'local') ? 'Create' : 'Edit';
            const eventDataWithDescription = {
                ...eventData,
                description: eventDescription,
                step: 1
            }

            let toastId
            if (eventType === 'Create') {
                toastId = toast.loading('Creating Event')
                // await createEventValidationBasic.validate(eventDataWithDescription, { abortEarly: false });
                const res = await createNewEvent(eventDataWithDescription)
                if (res?.success) {
                    navigate(`${EVENTS.CREATE_AND_EDIT_EVENT_ADVANCE}/${encodeToBase64('create')}/${encodeToBase64(res?.data?.id)}`)
                }
            } else {
                // eslint-disable-next-line unused-imports/no-unused-vars
                const { enable_connections, sold_out, current_step, createdAt, updatedAt, publish_status, id, ...other } = eventDataWithDescription
                const res = await editEvent(id, other)
                toastId = toast.loading('Updating Event')
                if (res?.success) {
                    navigate(`${EVENTS.CREATE_AND_EDIT_EVENT_ADVANCE}/${encodeToBase64('edit')}/${encodeToBase64(id)}`)
                }
            }
            deleteEventBySlugFromLocal(encodeToBase64(id) || eventDataWithDescription.slug)
            toast.dismiss(toastId)
        } catch (error) {
            toast.dismiss()
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const renderInput = (item) => {
        switch (item?.type) {
            case 'dropDown':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="h-[56px] w-[400px] mb-8 flex items-center rounded-3xl">
                            <DropDown
                                selectedValue={eventData?.[item?.key]}
                                placeholderValue={item?.label}
                                options={item?.options}
                                mode={item?.mode}
                                handleOnChange={(value) => updateState(item?.key, value)}
                            />
                        </div>
                    </div>
                )
            case 'text':
                return (
                    <div>
                        <div className='font-semibold'>{item?.label} </div>
                        <div className="h-[56px] w-[400px] mb-8 flex items-center rounded-3xl">
                            <InputField
                                value={eventData?.[item?.key]}
                                placeholder={item?.label}
                                onChange={(value) => updateState(item?.key, value)}
                                type={item?.inputFiledType}
                                className="w-[400px]"
                            />
                        </div>
                    </div>
                )
            case 'rangeSliderDoublePointer':
                return (
                    <div className="h-[120px] w-[400px] flex flex-col justify-center border-2 border-gray-300 rounded-3xl">
                        <p className='text-sm text-gray-500'>{`${item?.label1} ${eventData?.[item?.key1]} to ${eventData?.[item?.key2]} ${item?.label2}`}</p>
                        <RangeSliderDoublePointer
                            minMaxRange={item?.range}
                            selectedMinMaxValue={[eventData?.[item?.key1], eventData?.[item?.key2]]}
                            onRangeChange={(values) => {
                                updateState(item?.key1, values[0]);
                                updateState(item?.key2, values[1]);
                            }}
                        />
                    </div>
                )
        }
    }

    return (
        <div>
            <div className="flex items-start justify-between mt-16 mb-5">
                <h1 className='text-lg font-semibold'>What type of event are you creating</h1>
                {slugSuggestionBtnVisibility &&
                    <button onClick={generateSlug} className='p-2 px-4 text-white bg-brandRed rounded-2xl'>Generate Slug</button>
                }
            </div>
            <div className="flex justify-between">
                <div>
                    {contentLeftSide?.map((item, index) => (
                        <div key={index} className='w-[45%]'>
                            {renderInput(item)}
                        </div>
                    ))}
                </div>

                <div>
                    {contentRightSide?.map((item, index) => (
                        item?.isVisible && (
                            <div key={index} className='w-[45%]'>
                                {renderInput(item)}
                            </div>
                        )
                    ))}
                </div>
            </div>

            {/* Image */}

            <div className='flex justify-between w-full'>
                <div className="w-[45%]">
                    <TextEditor setData={setEventDescription} data={eventDescription} />
                </div>

                <>
                    {advanceCropping &&
                        <div className="h-[30vh] w-[50%] flex items-center justify-center">
                            <AdvancedImageCropping image={imgUrl} setPopup={() => setAdvanceCropping(!advanceCropping)} onCropComplete={handleCropComplete} ratio={11 / 6} />
                        </div>
                    }
                    <div onClick={handleButtonClick} className="h-[280px] w-[45%] my-8 flex flex-col justify-center items-center rounded-[2px] bg-white text-black border-2 border-gray-300 cursor-pointer">
                        {imgUrl ? (
                            <img src={imgUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '280px' }} />
                        ) : (
                            <>
                                <p className='text-[9px] text-gray-500'>supports: PNG, JPG, JPEG</p>
                                <img src={img} alt="" />
                                <p className='text-[9px] text-gray-500'>Click here or drag file to this area to upload</p>
                            </>
                        )}
                        <input type="file" onChange={handleImageChange} style={{ display: 'none' }} ref={fileInputRef} />
                    </div>
                </>

            </div>



            <div className="flex items-center justify-center w-full gap-5 mb-10">
                <button onClick={() => handleSaveAndContinue()} className='p-2 px-4 text-white bg-brandRed rounded-2xl'>Save And Continue</button>
                <button onClick={() => handleSaveInLocal()} className='p-2 px-4 text-white bg-green-500 rounded-2xl'>Save To Local</button>
            </div>
        </div>
    )
}

export default memo(Index)