import { getEventActivity } from 'apis/events'
import { EVENTS, USERS } from 'common/routes'
import React, { useEffect, useState } from 'react'
import { capitalize } from 'shared/textConverter'
import { formatDate, formatDateTo12HourTime } from 'utils/dataConversion'
import { encodeToBase64 } from 'utils/encodeAndDecode'

function Index() {
    const [userActivity, setUserActivity] = useState([])
    const [pagination, setPagination] = useState({
        current_page: 1,
        next_page: null,
    })


    const fetchUserActivity = async (page) => {
        const res = await getEventActivity(page)
        setPagination({ ...res?.pagination })
        setUserActivity(prev => ([...prev, ...res?.data]))
    }

    useEffect(() => {
        fetchUserActivity(pagination.current_page)
    }, [])

    const handleMemberId = (data) => {
        const id = encodeToBase64(data)
        const url = USERS.EDIT_USER_DETAILS.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleEventId = (data, comment = false) => {
        const url = `${EVENTS.VIEW_EVENT}/${encodeToBase64(data)}/${comment} `
        window.open(url, '_blank', 'noopener,noreferrer');
    };


    return (
        <div className='bg-white text-black -mt-4 w-[50%]'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Event Activity</h1>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-full">
                        {userActivity?.map((item, index) => (
                            <div key={index} className="flex items-center my-5">
                                <h1 className='text-lg font-semibold text-gray-500'>{formatDate(item?.createdAt)}</h1>
                                <div className="border-s-2 ms-8">
                                    <div className="ms-8">
                                        <h1 className='text-[11px] font-medium text-gray-500'>{formatDateTo12HourTime(item?.createdAt)}</h1>
                                        <h1 className='my-2 text-lg'>{capitalize(item?.actionName)}</h1>
                                        <h1 className='text-gray-500 text-sm max-w-[380px]'>{item?.message}</h1>
                                        {item?.eventId &&
                                            <h1 className='text-gray-700 text-sm max-w-[380px] mt-1'>Event Name:<span className='text-blue-200 cursor-pointer' title='Open in New Tab' onClick={() => handleEventId(item?.event?.slug)}> {item?.event?.title}</span></h1>
                                        }
                                        {item?.userId &&
                                            <h1 className='text-gray-700 text-sm max-w-[380px] mt-1'>MemberId : <span className='text-blue-200 cursor-pointer' title='Open in New Tab' onClick={() => handleMemberId(item?.userId)}> {item?.userId}</span></h1>
                                        }
                                        {item?.url &&
                                            <div className='text-gray-700 text-sm max-w-[380px] mt-1 flex items-center me-2'>
                                                <p>Image URL :</p>
                                                <a href={item?.url} target="_blank" rel="noopener noreferrer" className="flex items-center p-2 px-3 border ms-2">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.6668 1.66797H5.00016C4.55814 1.66797 4.13421 1.84356 3.82165 2.15612C3.50909 2.46868 3.3335 2.89261 3.3335 3.33464V16.668C3.3335 17.11 3.50909 17.5339 3.82165 17.8465C4.13421 18.159 4.55814 18.3346 5.00016 18.3346H15.0002C15.4422 18.3346 15.8661 18.159 16.1787 17.8465C16.4912 17.5339 16.6668 17.11 16.6668 16.668V6.66797M11.6668 1.66797L16.6668 6.66797M11.6668 1.66797V6.66797H16.6668M13.3335 10.8346H6.66683M13.3335 14.168H6.66683M8.3335 7.5013H6.66683" stroke="#6172F3" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    <p>Click here</p>
                                                </a>

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                        {pagination?.next_page !== null &&
                            <div className='w-full mt-2 text-center text-blue-500 cursor-pointer' onClick={() => fetchUserActivity(pagination.next_page)}>see More</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)