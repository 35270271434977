import { DropDown, InputField } from 'components';
import React from 'react';
import { FaTimes } from 'react-icons/fa';

const FilterCard = ({
    label,
    handleFilterType,
    handleValueChange,
    selectedFilterType,
    selectedValue,
    handleOnClose,
    inputType,
    valuesOption,
    fieldsToHideIncludeExclude = [],
    dateFields = []
}) => {


    return (
        <div className="relative w-full p-4 border border-gray-300 rounded-lg shadow-sm bg-gray-50">
            {/* Close Button */}
            <button onClick={handleOnClose} className="absolute text-gray-500 top-2 right-2 hover:text-gray-800">
                <FaTimes />
            </button>

            {/* Label */}
            <label className="block mb-1 text-sm font-medium ">{label}</label>

            {/* Include and Exclude */}

            {!fieldsToHideIncludeExclude.includes(label) &&
                <div className="">
                    <DropDown
                        placeholderValue={'Include or Exclude'}
                        height={'h-[35px]'}
                        options={[{ label: 'Include', value: "include" }, { label: "Exclude", value: "exclude" }]}
                        handleOnChange={handleFilterType}
                        selectedValue={selectedFilterType}
                    />
                </div>
            }

            {/* Values */}
            {inputType === 'singleDropdown' &&
                <div className="mt-2">
                    <DropDown
                        height={'h-[35px]'}
                        mode={'multiple'}
                        options={valuesOption}
                        handleOnChange={handleValueChange}
                        selectedValue={selectedValue}
                    />
                </div>
            }

            {inputType === 'input' &&
                <div className="mt-2">
                    <InputField
                        height={'h-[35px]'}
                        onChange={(value) => handleValueChange([value])}
                        value={selectedValue}
                        type={dateFields.includes(label) ? 'date' : 'text'}
                    />
                </div>
            }

        </div>
    );
};

export default FilterCard;
