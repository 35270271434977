// Events
export const EVENTS = {
    EVENTS: "/events",
    EVENTS_CONNECTIONS:"/event/connection",
    EVENTS_COMMENTS:"/event/comments",
    EVENTS_CATEGORY:"/event/category",
    EVENTS_LOCATION:"/event/location",
    EVENTS_TAG:"/event/tag",
    EVENTS_HOST: "/event/host",
    EVENTS_ACTIVITY: "/event/activity",
    CREATE_AND_EDIT_EVENT_BASIC: "/event/basic",
    CREATE_AND_EDIT_EVENT_ADVANCE: "/event/advance",
    VIEW_EVENT: "/event/view",
    VIEW_EVENT_PARTICIPANT: "/event/participant",
    ELIGIBLE_PARTICIPANTS: "/event/eligible/participant",
}

export const USERS = {
    VIEW_USERS: "/users",
    VIEW_ACTIVITY_BASED_USERS: "/users/activity",
    VIEW_SETTINGS_BASED_USERS: "/users/settings",
    EDIT_USER_DETAILS:'/user/details/edit/:id',
    DELETED_USERS: "/users/deleted",
    CREATE_USERS: "/users/create",
    USERS_PLAN: "/users/plan",
}

export const PLANS = {
    PLANS:'/plan',
    PLAN_CATEGORY: '/plan/category',
    PLAN_LIMITATION: '/plan/limitation',
    PLAN_COIN: '/plan/coin',
}

export const NOTIFICATION = {
    NOTIFICATION:"/notification",
    HISTORY:"/notification/history"
}

export const BLOG = {
    BLOG: '/blog',
    CREATE_BLOG: '/blog/create/:id?',
    VIEW_BLOG: '/blog/view/:id',
}

export const AFFILIATE_COUPONS = {
    AFFILIATE_COUPONS:"/affiliateCoupon",
    TRACK_AFFILIATE_COUPON: '/affiliateCoupon/track/:id',
}

export const OFFER = {
    OFFER: '/offer',
    CREATE_OFFER:'/offer/create'
}

export const HOTSPOT = {
    HOTSPOT: '/hotspot',
    CREATE_HOTSPOT:'/hotspot/create'
}

export const AFFINITY_SCORE = {
    AFFINITY_SCORE: '/affinity',
    CREATE_AFFINITY_SCORE:'/affinity/create'
}

export const RELIGION_AND_CAST = {
    RELIGION: '/religion',
    CAST:'/cast'
}

export const HOME = {
    HOME_OVERVIEW: "/",
    EXTERNAL_SERVICES:"/external-services"
}

export const SIDEBAR = {
    // Home
    HOME_OVERVIEW:HOME.HOME_OVERVIEW,
    EXTERNAL_SERVICES:HOME.EXTERNAL_SERVICES,

    // EVENT
    EVENTS: EVENTS.EVENTS,
    EVENTS_CONNECTIONS:EVENTS.EVENTS_CONNECTIONS,
    EVENTS_COMMENTS:EVENTS.EVENTS_COMMENTS,
    EVENTS_CATEGORY:EVENTS.EVENTS_CATEGORY,
    EVENTS_LOCATION:EVENTS.EVENTS_LOCATION,
    EVENTS_TAG:EVENTS.EVENTS_TAG,
    EVENTS_HOST: EVENTS.EVENTS_HOST,
    EVENTS_ACTIVITY: EVENTS.EVENTS_ACTIVITY,

    // USER
    VIEW_USERS: USERS.VIEW_USERS,
    VIEW_ACTIVITY_BASED_USERS:USERS.VIEW_ACTIVITY_BASED_USERS,
    VIEW_SETTINGS_BASED_USERS:USERS.VIEW_SETTINGS_BASED_USERS,
    DELETED_USERS: USERS.DELETED_USERS,
    USERS_PLAN: USERS.USERS_PLAN,

    // PLAN and LIMITATION
    PLANS:PLANS.PLANS,
    PLAN_CATEGORY: PLANS.PLAN_CATEGORY,
    PLAN_LIMITATION: PLANS.PLAN_LIMITATION,
    PLAN_COIN: PLANS.PLAN_COIN,
    
    // Coupon
    COUPON: '/coupon',

    // Notification
    NOTIFICATION: NOTIFICATION.NOTIFICATION,
    NOTIFICATION_HISTORY: NOTIFICATION.HISTORY,
    
    // Verification
    VERIFICATION:'/verification',
    
    // AppUpdate
    APP_UPDATE: '/appUpdate',
    
    // Blog
    BLOG:BLOG.BLOG,

    // Affiliate Coupons
    AFFILIATE_COUPONS:AFFILIATE_COUPONS.AFFILIATE_COUPONS,
    
    // Offer
    OFFER: OFFER.OFFER,
    
    // HOTSPOT
    HOTSPOT:HOTSPOT.HOTSPOT,

    // Affinity Score
    AFFINITY_SCORE: AFFINITY_SCORE.AFFINITY_SCORE,
    
    // RELIGION_AND_CAST Mangement
    RELIGION:RELIGION_AND_CAST.RELIGION,
    CAST: RELIGION_AND_CAST.CAST,
    
    // ENQUIRY
    PLAN_ENQUIRY: '/plan/enquiry',
}