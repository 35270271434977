import { getAllPlans, } from "apis/plans";
import React, { useEffect, useState } from "react";
import { capitalize } from "shared/textConverter";

function Index({ handleFilterData, handleShowFilter }) {
    const [selectedPlan, setSelectedPlan] = useState({
        page: 1,
        limit: 10,
        plans: []
    });
    const [actionName, setActionName] = useState([]);
    const [searchText, setSearchText] = useState("");

    const fetchAllPlans = async () => {
        const res = await getAllPlans();
        setActionName(res?.data);
    };

    const toggleName = (id) => {
        setSelectedPlan((prevSelectedPlan) => {
            const updatedPlanIds = prevSelectedPlan.plans.includes(id)
                ? prevSelectedPlan.plans.filter((item) => item !== id)
                : [...prevSelectedPlan.plans, id];

            return { ...prevSelectedPlan, plans: updatedPlanIds };
        });
    };


    useEffect(() => {
        fetchAllPlans();
        const storedQuery = JSON.parse(sessionStorage.getItem("paidUsersByPlan") || "[]");
        setSelectedPlan({
            limit: storedQuery.limit || 10,
            page: storedQuery.page || 1,
            plans: storedQuery.plans || []
        });
    }, []);

    // Filter action names based on search text
    const filteredActionNames = actionName.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="flex items-center justify-center h-screen">
            <div className="w-full h-full max-h-screen p-3 overflow-y-auto border border-gray-300">
                <div className="relative flex flex-col">
                    <div className="mb-5 text-lg font-semibold">Activity Names</div>

                    {/* Search Input */}
                    <div className="mb-4">
                        <input
                            type="text"
                            placeholder="Search activities..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    {/* List of action names */}
                    {filteredActionNames.map((item) => {
                        const isSelected = selectedPlan.plans?.includes(item.id);
                        return (
                            <div
                                key={item.id}
                                onClick={() => toggleName(item.id)}
                                className={`flex cursor-pointer p-2 my-1 border ${isSelected ? "bg-gray-300" : ""
                                    }`}
                            >
                                {capitalize(item.name)}
                            </div>
                        );
                    })}
                </div>

                <div className="flex justify-center gap-4 p-4 mt-auto">
                    <button
                        onClick={() => handleFilterData(selectedPlan)}
                        className="p-4 font-semibold text-white transition duration-300 bg-green-500 rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
                    >
                        Submit
                    </button>
                    <button
                        onClick={handleShowFilter}
                        className="p-4 font-semibold text-white transition duration-300 bg-red-500 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Index;
