import { ManagementSecMainHeader, Table } from "components";
import React, { lazy, Suspense, useEffect, useState } from 'react';

import { USERS } from 'common/routes';
import DropDown from 'components/NEW/ui/dropDown';
import { toast } from 'react-toastify';
import { enquiryStatus } from 'shared/constants';
import { encodeToBase64 } from 'utils/encodeAndDecode';
import { getEnquiryBasedOnStatus, updateEnquiryStatusByID } from "apis/enquiry";

const ConfirmationPopup = lazy(() => import('components/NEW/popups/confirm'))


function Index() {

    const [userData, setUserData] = useState([])
    const [status, setStatus] = useState('submit')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [isConfirmationPopup, setIsConfirmationPopup] = useState({
        visibility: false,
        id: null,
        status: null,
    })
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })


    const columns = [

        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        {
            Header: 'Name',
            accessor: 'user.name',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleViewUserProfile(row)} className='cursor-pointer hover:text-blue-500 hover:underline'>{row?.original?.user?.name}</button>
                </div>
            )
        },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Phone', accessor: 'user.phone' },
        {
            Header: 'Photo',
            accessor: 'user.photo',
            Cell: ({ value }) => (
                <img src={value} alt="userPhoto" className='w-[66px] h-[70px] cursor-pointer' />
            )
        },
        {
            Header: 'Actions',
            accessor: 'status',
            Cell: ({ row }) => (
                <div>
                    <DropDown
                        selectedValue={row?.original?.status}
                        options={enquiryStatus}
                        handleOnChange={(value) => setIsConfirmationPopup({ id: row?.original?.id, status: value, visibility: true })}
                    />
                </div>
            )
        }
    ];

    const handleUpdateStatus = async () => {
        const res = await updateEnquiryStatusByID(isConfirmationPopup.id, isConfirmationPopup.status)
        if (res?.success) {
            toast.success('Updated Successfully')
            fetchEnquiryBasedOnStatus(pageNation.currentPage, rowsPerPage, status)
        }
        setIsConfirmationPopup({ visibility: false })
    }


    const handleViewUserProfile = (row) => {
        const id = encodeToBase64(row.original.userId)
        const url = USERS.EDIT_USER_DETAILS.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };



    const fetchEnquiryBasedOnStatus = async (page, limit = rowsPerPage,) => {
        const res = await getEnquiryBasedOnStatus(page, limit, status)
        // Store the data in session storage
        const filters = { page, limit, status }
        sessionStorage.setItem('verificationFilter', JSON.stringify(filters));

        setUserData(res?.data)
        const data = res?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        setUserData([])
        fetchEnquiryBasedOnStatus(pageNation.currentPage)
    }, [rowsPerPage, status])

    useEffect(() => {
        const storedFilters = sessionStorage.getItem('verificationFilter');
        if (storedFilters) {
            const filters = JSON.parse(storedFilters);
            setStatus(filters.status || 'requested');
            setRowsPerPage(filters.limit || 10);
        }
    }, []);

    return (
        <div className='flex flex-col h-screen'>
            {isConfirmationPopup.visibility &&
                <Suspense>
                    <ConfirmationPopup onClose={() => setIsConfirmationPopup({ visibility: false })} onConfirm={handleUpdateStatus}
                        text={`Are you sure you want to update the status to ${isConfirmationPopup.status}`}
                    />
                </Suspense>
            }

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Verification"}
                        subHeading={"user Verification"}
                    />
                </div>
                {/* Table */}
                <div className="p-8">
                    <div className='flex justify-between'>
                        <div className="flex">
                            <button onClick={() => setStatus('submit')} className={`p-2 px-6 border ${status === 'submit' && 'bg-gray-300'}`}>Submit</button>
                            <button onClick={() => setStatus('solved')} className={`p-2 px-6 border-2 border-b-0 ${status === 'solved' && 'bg-gray-300'}`}>Solved</button>
                        </div>

                        <div className="flex items-center">
                            <label htmlFor="rowsPerPage" className="block text-sm font-medium text-gray-700">
                                Rows per page:
                            </label>
                            <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                value={rowsPerPage}
                                onChange={(e) => setRowsPerPage(e.target.value)}
                                className="block p-2 px-6 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                    </div>
                    <>
                        {(userData?.length > 0) ?
                            <Table columns={columns} data={userData} pageNation={pageNation} handlePageNation={(value) => fetchEnquiryBasedOnStatus(value)} rowsPerPage={rowsPerPage} />
                            :
                            <>
                                {userData === false ?
                                    <div className="flex items-center justify-center w-full text-2xl text-black">No User Found</div>
                                    :
                                    <div className="flex items-center justify-center w-full text-2xl text-black">Loading the Data please wait...</div>
                                }
                            </>
                        }
                    </>
                </div>

            </div>
        </div>
    )
}

export default Index