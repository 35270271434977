
import React, { useEffect, useState } from 'react';
import StatsCard from 'components/NEW/cards/homeOverview';
import { ManagementSecMainHeader } from "components"
import { getDashboardOverview } from 'apis/home';
import { capitalize } from 'shared/textConverter';


const Index = () => {
    const [overView, setOverView] = useState()
    const fetchOverView = async () => {
        const res = await getDashboardOverview()
        if (res?.data) {
            setOverView(res?.data)
        }
    }

    useEffect(() => {
        fetchOverView()
    }, [])

    return (
        <div>
            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Overview"}
                        subHeading={"Overview"}
                        btnText={"View"}
                        handleBtn={"handleBtn"}
                    />
                </div>
                <div className="min-h-screen p-8 bg-gray-100">
                    <div className="container mx-auto">
                        {overView?.map((item, index) => (
                            <StatsCard key={index} title={capitalize(item?.label)} stats={item?.value} />
                        ))}
                    </div>
                </div>

            </div>


        </div>
    );
};

export default Index;