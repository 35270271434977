import React, { useEffect, useState } from 'react'
import { USERS } from "common/routes.js"
import { ManagementSecMainHeader, Table } from "components"
import { useNavigate } from 'react-router-dom'

import { getAllUsersV2 } from 'apis/users/index.js'
import { formatDateToDDMMYYInNumber } from 'shared/dates.js'
import { encodeToBase64 } from 'utils/encodeAndDecode'

import verification from 'assets/icons/verified.png'
import { PaidUserByPlan } from "components/NEW/filters"


function Index() {

    const navigate = useNavigate()
    const [userData, setUserData] = useState()
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })
    const [rowsPerPage, setRowsPerPage] = useState(10)

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        {
            Header: 'JoinedAt', accessor: 'created_at',
            Cell: ({ row }) => (
                <div onClick={() => handleEdit(row)} className="text-blue-500 underline cursor-pointer">{formatDateToDDMMYYInNumber(row?.original?.created_at)}</div>
            )
        },
        {
            Header: 'Name', accessor: 'name',
            Cell: ({ row }) => (
                <div onClick={() => handleEdit(row)} className="text-blue-500 underline cursor-pointer">{row?.original?.name}</div>
            )
        },
        {
            Header: 'Email',
            accessor: 'email',
            Cell: ({ row }) => (
                <div className='flex items-center'>
                    <p>{row.original.email}</p>
                    {row.original.verification?.status === 'verified' && (
                        <img src={verification} alt="Verified" className='ms-2 w-[20px] h-[20px]' />
                    )}
                </div>
            )
        },
        { Header: 'Plan', accessor: 'wallet.current_plan.name' },
        {
            Header: 'plan Purchase Date', accessor: 'wallet.plan_purchased_date',
            Cell: ({ row }) => (
                <div>{formatDateToDDMMYYInNumber(row?.original?.wallet?.plan_purchased_date)}</div>
            )
        },
        {
            Header: 'plan Expiry', accessor: 'wallet.plan_expiry',
            Cell: ({ row }) => (
                <div>{formatDateToDDMMYYInNumber(row?.original?.wallet?.plan_expiry)}</div>
            )
        },
        {
            Header: 'Photo',
            accessor: 'photo',
            Cell: ({ value }) => (
                <img src={value} alt="No Photo" className='w-[66px] h-[70px] cursor-pointer' />
            )
        },
        { Header: 'Phone', accessor: 'phone' },
    ];


    const handleEdit = (row) => {
        const id = encodeToBase64(row.original.id)
        const url = USERS.EDIT_USER_DETAILS.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleFilterData = (value) => {
        const savedFilters = JSON.parse(sessionStorage.getItem('paidUsersByPlan'));
        fetchAllUsers(savedFilters?.page || 1, value)
    }

    const transformData = (data) => {
        return {
            "0": {
                "key": "plans",
                "filterType": "include",
                "values": data.plans
            },
            "limit": data.limit,
            "page": data.page,
        };
    };

    const fetchAllUsers = async (page = 1, value, limit = rowsPerPage) => {
        setUserData(null)
        const savedFilters = JSON.parse(sessionStorage.getItem('paidUsersByPlan'));
        let updatedData
        if (value) {

            updatedData = {
                ...value,
                page: page,
                limit: limit
            };
        } else if (savedFilters) {
            updatedData = {
                ...savedFilters,
                page: page,
                limit: limit
            };
        } else {
            updatedData = {
                page: page,
                limit: limit
            };
        }
        const payload = transformData(updatedData)
        const res = await getAllUsersV2(payload)
        setUserData(res?.data)
        const data = res?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
        sessionStorage.setItem('paidUsersByPlan', JSON.stringify(updatedData));
    }


    useEffect(() => {
        const savedFilters = JSON.parse(sessionStorage.getItem('paidUsersByPlan'));
        fetchAllUsers(savedFilters?.page || 1, savedFilters || null)
    }, [])


    const handleBtn = () => {
        navigate(USERS.CREATE_USERS)
    }

    return (
        <div className='flex flex-col h-screen'>

            {/* Main Content Section */}
            <div className="bg-[#f8f8f8] h-screen w-full">
                {/* Header */}
                <div className="w-[96%] mx-auto my-10">
                    <ManagementSecMainHeader
                        mainHeading={"Create User"}
                        subHeading={"user Management"}
                        btnText={"Add User"}
                        handleBtn={handleBtn}
                        handleFilterData={(value) => handleFilterData(value)}
                        FilterComponent={PaidUserByPlan}
                    />
                </div>
                {/* Table */}
                <div className="p-8">
                    <div className="flex items-center">

                        <div className="flex items-center">
                            <label htmlFor="rowsPerPage" className="block text-sm font-medium text-gray-700">
                                Rows per page:
                            </label>
                            <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                value={rowsPerPage}
                                onChange={(e) => {
                                    setRowsPerPage(e.target.value)
                                    fetchAllUsers(pageNation.currentPage, null, e.target.value)
                                }}
                                className="block p-2 px-6 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">

                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={200}>200</option>
                                <option value={500}>500</option>
                            </select>
                        </div>
                        {userData &&
                            <div className="flex items-center ms-2">
                                <h1>Total Filtered Users</h1>
                                <h1 className='font-semibold ms-2'>: {userData.length > 0 ? userData.length : 0}</h1>
                            </div>
                        }
                    </div>

                    {userData ?
                        <Table columns={columns} data={userData} pageNation={pageNation} handlePageNation={(value) => fetchAllUsers(value)} rowsPerPage={rowsPerPage} />
                        :
                        <div className="flex items-center justify-center w-full text-2xl text-black">Loading the Data Please Wait</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Index