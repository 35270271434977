import { auth } from "apis";

export const getEnquiryBasedOnStatus = async (page,limit,status) => {
    const res = await auth({
        method: "GET",
        url: `/admin/enquiry/${status}?page=${page}&limit=${limit}`,
    });
    return res;
}

export const updateEnquiryStatusByID = async (id, status) => {
    const res = await auth({
        method: "PUT",
        url: `/admin/enquiry/${id}?status=${status}`,
    });
    return res;
}